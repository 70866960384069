import React, { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import {
  alpha,
  AppBar,
  Box,
  createTheme,
  IconButton,
  InputBase,
  Stack,
  styled,
  ThemeProvider,
  Toolbar,
  Typography,
} from '@mui/material';
import LogoutIcon from '@mui/icons-material/Logout';
import LanIcon from '@mui/icons-material/Lan';
import SearchIcon from '@mui/icons-material/Search';
import { useNavigate } from 'react-router-dom';
import Logo from '../../image/logo.png';
import {
  DEVICES_ROUTE,
  MAIN_ROUTE,
  SIGN_IN_ROUTE,
} from '../../constant/routes';
import {
  DEVICE_STATUS,
  IP_LOCAL_NAME,
  MOBILE_VIEW,
} from '../../constant/constants';
import { DeviceStageEnum } from '../../enum/DeviceStageEnum';
import {
  checkDiffUnixTime,
  descrActualApp,
  getDefaultAlertProps,
  getDeviceStageDescription,
  getIsCloud,
  getViewType,
  unixToLocal,
} from '../../utils/utils';
import { IApiResult } from '../../interface/IApiResult';
import { apiLogout } from '../../service/account/apiLogout';
import { AlertSeverityEnum } from '../../enum/AlertSeverityEnum';
import { IAlertProps } from '../../interface/IAlertProps';
import { MyAlert } from '../UI/MyAlert';
import { ActualVersDto } from '../../dto/ActualVersDto';
import { apiGetActualVers } from '../../service/localbase/apiGetActualVers';

const Search = styled('div')(({ theme }) => ({
  position: 'relative',
  borderRadius: theme.shape.borderRadius,
  backgroundColor: alpha(theme.palette.common.white, 0.15),
  '&:hover': {
    backgroundColor: alpha(theme.palette.common.white, 0.25),
  },
  marginLeft: 0,
  width: '100%',
  [theme.breakpoints.up('sm')]: {
    marginLeft: theme.spacing(1),
    width: 'auto',
  },
}));

const SearchIconWrapper = styled('div')(({ theme }) => ({
  padding: theme.spacing(0, 2),
  height: '100%',
  position: 'absolute',
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'center',
  cursor: 'pointer',
}));

const StyledInputBase = styled(InputBase)(({ theme }) => ({
  color: 'inherit',
  '& .MuiInputBase-input': {
    padding: theme.spacing(1, 1, 1, 0),
    paddingLeft: `calc(1em + ${theme.spacing(4)})`,
    transition: theme.transitions.create('width'),
    width: '100%',
    [theme.breakpoints.up('sm')]: {
      width: '16ch',
      '&:focus': {
        width: '20ch',
      },
    },
  },
}));

export const MainNavigationBar = () => {
  const isCloud = getIsCloud();
  const navigate = useNavigate();
  const { t } = useTranslation();
  const [alertProps, setAlertProps] = useState<IAlertProps>(
    getDefaultAlertProps()
  );
  const [actualVersDto, setActualVersDto] = useState<ActualVersDto>();
  const [search, setSearch] = useState('');
  const handleClick = () => {
    localStorage.setItem(
      DEVICE_STATUS,
      getDeviceStageDescription(DeviceStageEnum.SEARCH)
    );
    navigate(`${DEVICES_ROUTE}/${search}`);
  };

  const getActualVers = async () => {
    const apiResult: IApiResult = await apiGetActualVers();
    if (apiResult.isSuccess) {
      setActualVersDto(apiResult.data);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const darkTheme = createTheme({
    palette: {
      mode: 'light',
      primary: {
        main: '#212121',
      },
    },
  });

  const logout = async () => {
    const apiResult: IApiResult = await apiLogout();
    if (apiResult.isSuccess) {
      navigate(SIGN_IN_ROUTE);
    } else {
      setAlertProps({
        message: t('errotServer'),
        severity: AlertSeverityEnum.error,
      });
    }
  };

  const handleLogout = async () => {
    await logout();
  };

  useEffect(() => {
    getActualVers();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Box sx={{ flexGrow: 1 }}>
      <MyAlert
        message={alertProps.message}
        severity={alertProps.severity}
        onClose={() => setAlertProps({ ...alertProps, message: '' })}
      />
      <ThemeProvider theme={darkTheme}>
        <AppBar position="static">
          <Toolbar>
            <Stack direction="row" width="100%" justifyContent="space-between">
              <Stack direction="row" alignItems="center" spacing={2}>
                <Stack direction="row" alignItems="center" spacing={2}>
                  <Stack
                    direction="row"
                    alignItems="center"
                    spacing={2}
                    onClick={() => navigate(MAIN_ROUTE)}
                  >
                    <img alt="" src={Logo} style={{ cursor: 'pointer' }} />
                    <Stack direction="row" alignItems="center" spacing={0}>
                      <Typography
                        style={{
                          fontFamily: 'sans-serif',
                          fontStyle: 'revert',
                          fontWeight: 750,
                          fontSize: '28px',
                          color: '#FFFFFF',
                        }}
                        component="text"
                      >
                        Про
                      </Typography>
                      <Typography
                        style={{
                          fontFamily: 'sans-serif',
                          fontStyle: 'normal',
                          fontWeight: 100,
                          fontSize: '28px',
                          color: '#FFFFFF',
                        }}
                        component="text"
                      >
                        НЕО
                      </Typography>
                    </Stack>
                  </Stack>
                  {false && (
                    <Stack direction="row" alignItems="center">
                      <Typography
                        style={{
                          fontFamily: 'sans-serif',
                          fontStyle: 'normal',
                          fontWeight: 100,
                          fontSize: '20px',
                          color: '#ef5350',
                        }}
                        component="text"
                      >
                        {descrActualApp(actualVersDto)}
                      </Typography>
                    </Stack>
                  )}
                </Stack>
              </Stack>
              <Stack direction="row" alignItems="center" spacing={1}>
                {getViewType() !== MOBILE_VIEW && (
                  <Search>
                    <SearchIconWrapper>
                      <SearchIcon
                        style={{ cursor: 'pointer' }}
                        onClick={handleClick}
                      />
                    </SearchIconWrapper>
                    <StyledInputBase
                      value={search}
                      placeholder={t('search')}
                      onChange={(e) => setSearch(e.target.value)}
                      onKeyPress={(ev) => {
                        if (ev.key === 'Enter' || ev.key === 'NumpadEnter') {
                          handleClick();
                          ev.preventDefault();
                        }
                      }}
                      style={{
                        fontFamily: 'sans-serif',
                        fontSize: '18px',
                      }}
                      inputProps={{ 'aria-label': 'search' }}
                    />
                  </Search>
                )}
                {getViewType() !== MOBILE_VIEW && !isCloud && (
                  <Stack direction="row" alignItems="center" spacing={1}>
                    <Typography
                      style={{
                        fontFamily: 'sans-serif',
                        fontStyle: 'normal',
                        fontWeight: 200,
                        fontSize: '20px',
                        color: '#FFFFFF',
                      }}
                      component="text"
                    >
                      {localStorage.getItem(IP_LOCAL_NAME)}
                    </Typography>
                    <IconButton
                      size="large"
                      title={
                        checkDiffUnixTime(
                          600,
                          actualVersDto?.datetime,
                          localStorage.getItem(IP_LOCAL_NAME) ?? ''
                        ) === 0
                          ? `В сети\n${unixToLocal(actualVersDto?.datetime)}`
                          : `Не в сети\n${unixToLocal(actualVersDto?.datetime)}`
                      }
                      color={
                        checkDiffUnixTime(
                          600,
                          actualVersDto?.datetime,
                          localStorage.getItem(IP_LOCAL_NAME) ?? ''
                        ) === 0
                          ? 'inherit'
                          : 'error'
                      }
                    >
                      <LanIcon fontSize="large" />
                    </IconButton>
                  </Stack>
                )}

                <IconButton
                  size="large"
                  title={t('tooltipExit')}
                  edge="start"
                  color="inherit"
                  onClick={handleLogout}
                  aria-label="menu"
                >
                  <LogoutIcon />
                </IconButton>
              </Stack>
            </Stack>
          </Toolbar>
        </AppBar>
      </ThemeProvider>
    </Box>
  );
};

import i18n from 'i18next';
import {
  ACTION_MAGNET_FLOW,
  ACTION_TEMP,
  IS_CLOUD_NAME,
  LANGUAGE_RUS,
  MAX_CONST_FLOW_TASK,
  MAX_CONST_KOMP_TASK,
  MAX_CONST_PRES_TASK,
  MAX_CONST_TEMP_TASK,
  MAX_CONTROL_TEMP_TASK,
  MAX_CONTR_DAY_TASK,
  MAX_CONTR_HOUR_TASK,
  MAX_MAX_SIGN_TASK,
  MAX_TEMP_GPRS_TASK,
  MIN_CONST_FLOW_TASK,
  MIN_CONST_KOMP_TASK,
  MIN_CONST_PRES_TASK,
  MIN_CONST_TEMP_TASK,
  MIN_CONTROL_TEMP_TASK,
  MIN_CONTR_DAY_TASK,
  MIN_CONTR_HOUR_TASK,
  MIN_MIN_SIGN_TASK,
  MIN_TEMP_GPRS_TASK,
  MOBILE_VIEW,
  PAD_VIEW,
  PC_VIEW,
  ROOT_NAME,
} from '../constant/constants';
import { DeviceArchiveDto } from '../dto/DeviceArchiveDto';
import { TaskDto } from '../dto/TaskDto';
import { AlertSeverityEnum } from '../enum/AlertSeverityEnum';
import { DeviceStageEnum } from '../enum/DeviceStageEnum';
import { IAlertProps } from '../interface/IAlertProps';
import { IApiResult } from '../interface/IApiResult';
import { ReportsTypeEnum } from '../enum/ReportsTypeEnum';
import { ActionMagnetFlowEnum } from '../enum/ActionMagnetFlowEnum';
import { ActionTempEnum } from '../enum/ActionTempEnum';
import { DispEnum } from '../enum/DispEnum';
import { KategoryEnum } from '../enum/KategoryEnum';
import { RootEnum } from '../enum/RootEnum';
import { GroupTaskDto } from '../dto/GroupTaskDto';
import { ActualVersDto } from '../dto/ActualVersDto';
import { TempMeasPeriod } from '../dto/TempMeasPeriod';
import { ShowVolumeEnum } from '../enum/ShowVolumeEnum';
import { DeviceSetupTaskDto } from '../dto/DeviceSetupTaskDto';
import { FactoryStageEnum } from '../enum/FactoryStageEnum';
import { EnableFlowMinEnum } from '../enum/EnableFlowMinEnum';
import { EnableValveEnum } from '../enum/EnableValveEnum';
import { ValveTypeEnum } from '../enum/ValveTypeEnum';
import { ValvePolEnum } from '../enum/ValvePolEnum';
import { AllErrorsDto } from '../dto/AllErrorsDto';

export const checkOneUpdateTask = (
  name: string,
  value: string,
  min: number,
  max: number
) => {
  let result = '';
  if (value !== '') {
    const val = parseFloat(value);
    if (val < min || val > max) {
      result += `${i18n.t(name)} ${i18n.t('outSpan')} ${i18n.t(
        'from'
      )} ${min} ${i18n.t('to')} ${max}\n`;
    }
  }
  return result;
};

export const checkUpdateSetupTask = (dto: DeviceSetupTaskDto) => {
  let result = '';
  result += checkOneUpdateTask(
    'constPres',
    dto.taskConstPres,
    MIN_CONST_PRES_TASK,
    MAX_CONST_PRES_TASK
  );

  result += checkOneUpdateTask(
    'constKomp',
    dto.taskConstKomp,
    MIN_CONST_KOMP_TASK,
    MAX_CONST_KOMP_TASK
  );

  result += checkOneUpdateTask(
    'constMinTemp',
    dto.taskConstTempMin,
    MIN_CONST_TEMP_TASK,
    MAX_CONST_TEMP_TASK
  );

  result += checkOneUpdateTask(
    'constMaxTemp',
    dto.taskConstTempMax,
    MIN_CONST_TEMP_TASK,
    MAX_CONST_TEMP_TASK
  );

  result += checkOneUpdateTask(
    'minTempLimit',
    dto.taskTempMinLimit,
    MIN_CONTROL_TEMP_TASK,
    MAX_CONTROL_TEMP_TASK
  );

  result += checkOneUpdateTask(
    'maxTempLimit',
    dto.taskTempMaxLimit,
    MIN_CONTROL_TEMP_TASK,
    MAX_CONTROL_TEMP_TASK
  );

  result += checkOneUpdateTask(
    'contrVal',
    dto.taskContrVal,
    MIN_CONST_FLOW_TASK,
    MAX_CONST_FLOW_TASK
  );

  result += checkOneUpdateTask(
    'contrHour',
    dto.taskContrHour,
    MIN_CONTR_HOUR_TASK,
    MAX_CONTR_HOUR_TASK
  );

  result += checkOneUpdateTask(
    'contrDay',
    dto.taskContrDay,
    MIN_CONTR_DAY_TASK,
    MAX_CONTR_DAY_TASK
  );

  result += checkOneUpdateTask(
    'minSign',
    dto.taskMinLevel,
    MIN_MIN_SIGN_TASK,
    MAX_MAX_SIGN_TASK
  );

  result += checkOneUpdateTask(
    'minGprsTemp',
    dto.taskMinTempGprs,
    MIN_TEMP_GPRS_TASK,
    MAX_TEMP_GPRS_TASK
  );

  return result;
};

export const checkUpdateSetupGroupTask = (dto: GroupTaskDto) => {
  let result = '';
  result += checkOneUpdateTask(
    'constPres',
    dto.taskConstPres,
    MIN_CONST_PRES_TASK,
    MAX_CONST_PRES_TASK
  );

  result += checkOneUpdateTask(
    'constKomp',
    dto.taskConstKomp,
    MIN_CONST_KOMP_TASK,
    MAX_CONST_KOMP_TASK
  );

  result += checkOneUpdateTask(
    'constMinTemp',
    dto.taskConstTempMin,
    MIN_CONST_TEMP_TASK,
    MAX_CONST_TEMP_TASK
  );

  result += checkOneUpdateTask(
    'constMaxTemp',
    dto.taskConstTempMax,
    MIN_CONST_TEMP_TASK,
    MAX_CONST_TEMP_TASK
  );

  result += checkOneUpdateTask(
    'minTempLimit',
    dto.taskTempMinLimit,
    MIN_CONTROL_TEMP_TASK,
    MAX_CONTROL_TEMP_TASK
  );

  result += checkOneUpdateTask(
    'maxTempLimit',
    dto.taskTempMaxLimit,
    MIN_CONTROL_TEMP_TASK,
    MAX_CONTROL_TEMP_TASK
  );

  result += checkOneUpdateTask(
    'contrVal',
    dto.taskContrVal,
    MIN_CONST_FLOW_TASK,
    MAX_CONST_FLOW_TASK
  );

  result += checkOneUpdateTask(
    'contrHour',
    dto.taskContrHour,
    MIN_CONTR_HOUR_TASK,
    MAX_CONTR_HOUR_TASK
  );

  result += checkOneUpdateTask(
    'contrDay',
    dto.taskContrDay,
    MIN_CONTR_DAY_TASK,
    MAX_CONTR_DAY_TASK
  );

  result += checkOneUpdateTask(
    'minSign',
    dto.taskMinLevel,
    MIN_MIN_SIGN_TASK,
    MAX_MAX_SIGN_TASK
  );

  result += checkOneUpdateTask(
    'minGprsTemp',
    dto.taskMinTempGprs,
    MIN_TEMP_GPRS_TASK,
    MAX_TEMP_GPRS_TASK
  );

  return result;
};

export const fwVers = (fw: string) => {
  const num = parseInt(fw, 10);
  // eslint-disable-next-line no-bitwise, @typescript-eslint/no-unused-vars
  const b1 = (num & 0xff000000) >> 24;
  // eslint-disable-next-line no-bitwise
  const b2 = (num & 0x00ff0000) >> 16;
  // eslint-disable-next-line no-bitwise, @typescript-eslint/no-unused-vars
  const b3 = (num & 0x0000ff00) >> 8;
  // eslint-disable-next-line no-bitwise
  const b4 = num & 0x000000ff;
  if (b4.toString().length === 1) {
    return `${b2}.0${b4}`;
  }
  return `${b2}.${b4}`;
};

export const formatVolumeToChart = (array: DeviceArchiveDto[]) => {
  let sumPart = 0;
  const resultArray: DeviceArchiveDto[] = [];
  for (let i = 0; i < array.length; i += 1) {
    sumPart += parseFloat(array[i].volumeSu);
    // eslint-disable-next-line no-param-reassign
    array[i].volumeSuSumDelta = sumPart.toString();
    resultArray.push(array[i]);
  }
  return resultArray;
};

export const formatDateArch = (dateTime?: string) => {
  if (dateTime === undefined) {
    return undefined;
  }
  if (dateTime.length < 5) {
    return dateTime;
  }
  if (dateTime.length < 9) {
    return new Intl.DateTimeFormat('ru-RU', {
      year: 'numeric',
      month: '2-digit',
    }).format(new Date(dateTime));
  }
  return new Intl.DateTimeFormat('ru-RU', {
    year: 'numeric',
    month: '2-digit',
    day: '2-digit',
  }).format(new Date(dateTime));
};

export const getRowColor = (error: string) => {
  if (error !== '0') {
    return 'stage-red';
  }
  return '';
};

export const getArrayMeasPeriod = () => {
  const tempMeasPeriodArray: TempMeasPeriod[] = [];
  tempMeasPeriodArray.push({
    id: 20,
    name: '20',
  });
  tempMeasPeriodArray.push({
    id: 40,
    name: '40',
  });
  tempMeasPeriodArray.push({
    id: 60,
    name: '60',
  });
  return tempMeasPeriodArray;
};

export const unixToLocal = (unix?: string) => {
  if (unix === undefined || unix === '') {
    return '';
  }
  const seconds = parseInt(unix, 10) * 1000;
  return new Intl.DateTimeFormat('ru-RU', {
    day: '2-digit',
    month: '2-digit',
    year: 'numeric',
    hour: '2-digit',
    minute: '2-digit',
    second: '2-digit',
  }).format(seconds);
};

export const checkDiffUnixTime = (diff: number, unix?: string, ip?: string) => {
  if (unix === undefined || unix === '' || ip === '91.245.227.7') {
    return 0;
  }
  const seconds = parseInt(unix, 10);
  Date.now();
  const nowSeconds = new Date().getTime() / 1000;
  const nowDiff = nowSeconds - seconds;
  if (nowDiff > diff) {
    return 1;
  }
  return 0;
};

export const formatNumberToBool = (data: string) => {
  if (data === '1') {
    return 'true';
  }
  return '';
};

export const formatResetBatteryToBool = (data: string) => {
  if (data === '100') {
    return 'true';
  }
  return '';
};

export const formatDateTask = (data: string) => {
  const arr = data.split(';');
  if (arr.length !== 2) {
    return '';
  }
  return `${unixToLocal(arr[1])} (${arr[0]})`;
};

export const formatDescrTask = (data: string) => {
  const arr = data.split('#*#');
  let result = '';
  for (let i = 0; i < arr.length; i += 1) {
    const arrItem = arr[i].split(':');
    if (arrItem[1] !== '') {
      if (arrItem[1] === 'true' || arrItem[0] === 'getBalance') {
        result += `${i18n.t(arrItem[0])}\n`;
      } else {
        result += `${i18n.t(arrItem[0])}: ${arrItem[1]}\n`;
      }
    }
  }
  return result;
};

export const formatDescrVolume = (data: string) => {
  const arr = data.split('#*#');
  let result = '';
  for (let i = 0; i < arr.length; i += 1) {
    const arrItem = arr[i].split(':');
    if (arrItem[0] !== '') {
      result += `${i18n.t(arrItem[0])}: ${arrItem[1]}\n`;
    }
  }
  return result;
};

export const getZroCnt = (needCnt: number, real: number) => {
  let result = '';
  const diffCnt = needCnt - real;
  if (diffCnt > 0) {
    for (let i = 0; i < diffCnt; i += 1) {
      result = `${result}0`;
    }
  }
  return result;
};

export const roundValue = (value: any, point: number, isRound: boolean) => {
  if (!isRound) {
    return value;
  }
  if (Number.isNaN(parseFloat(value))) {
    return value;
  }
  let formatValue = value;
  // eslint-disable-next-line no-restricted-globals
  if (isNaN(value)) {
    formatValue = value.replace(',', '.');
  }
  const result = Math.round(formatValue * point) / point;
  const needPoint = point.toString().length - 1;
  const arrResult = result.toString().split('.');
  let formResult = result.toString();
  if (arrResult.length === 1) {
    if (needPoint === 0) {
      formResult = `${result}`;
    } else {
      formResult = `${result}.${getZroCnt(needPoint, 0)}`;
    }
  }
  if (arrResult.length === 2) {
    if (needPoint === 0) {
      formResult = `${arrResult[0]}`;
    } else {
      formResult = `${result}${getZroCnt(needPoint, arrResult[1].length)}`;
    }
  }
  return formResult;
};

export const workTime = (time?: string) => {
  if (time === undefined) {
    return '';
  }
  const seconds = parseInt(time, 10);
  return roundValue(seconds / 3600, 100, true);
};

export const valveDescrStatus = (status?: number) => {
  switch (status) {
    case -1:
      return i18n.t('valveStatusMinus1');
    case 0:
      return i18n.t('valveStatus0');
    case 1:
      return i18n.t('valveStatus1');
    case 2:
      return i18n.t('valveStatus2');
    case 3:
      return i18n.t('valveStatus3');
    case 4:
      return i18n.t('valveStatus4');
    case 5:
      return i18n.t('valveStatus5');
    case 6:
      return i18n.t('valveStatus6');
    case 7:
      return i18n.t('valveStatus7');
    case 8:
      return i18n.t('valveStatus8');
    case 9:
      return i18n.t('valveStatus9');
    case 10:
      return i18n.t('valveStatus10');
    case 11:
      return i18n.t('valveStatus11');
    case 12:
      return i18n.t('valveStatus12');
    case 13:
      return i18n.t('valveStatus13');
    case 14:
      return i18n.t('valveStatus14');
    default:
      return i18n.t('valveStatusUndef');
  }
};

export const formatDescrTimeLeft = (data: string) => {
  const arr = data.split('#*#');
  let result = '';
  for (let i = 0; i < arr.length; i += 1) {
    const arrItem = arr[i].split(':');
    if (arrItem[1] !== '') {
      result += `${i18n.t(arrItem[0])}: ${workTime(arrItem[1])}\n`;
    }
  }
  return result;
};

export const formatDescrDeviceNum = (data: string) => {
  const arr = data.split('#*#');
  let result = '';
  for (let i = 0; i < arr.length; i += 1) {
    const arrItem = arr[i].split(':');
    if (arrItem[1] !== '-1') {
      result += `${i18n.t(arrItem[0])}: ${arrItem[1]}\n`;
    }
  }
  return result;
};

export const descrDepasStatus = (status: string) => {
  switch (status) {
    case '0':
      return i18n.t('depStatsu0');
    case '1':
      return i18n.t('depStatsu1');
    case '2':
      return i18n.t('depStatsu2');
    case '3':
      return i18n.t('depStatsu3');
    case '4':
      return i18n.t('depStatsu4');
    case '5':
      return i18n.t('depStatsu5');
    case '6':
      return i18n.t('depStatsu6');
    default:
      return '';
  }
};

export const formatDescrDepasivate = (data: string) => {
  const arr = data.split('#*#');
  let result = '';
  for (let i = 0; i < arr.length; i += 1) {
    const arrItem = arr[i].split(':');
    if (arrItem[1] !== '') {
      if (arrItem[0] === 'batDepResult') {
        result += `${i18n.t(arrItem[0])}: ${descrDepasStatus(arrItem[1])}\n`;
      } else {
        result += `${i18n.t(arrItem[0])}: ${arrItem[1]}\n`;
      }
    }
  }
  return result;
};

export const formatDescrGprs = (data: string) => {
  const arr = data.split('#*#');
  let result = '';
  for (let i = 0; i < arr.length; i += 1) {
    const arrItem = arr[i].split(':');
    if (arrItem[1] !== '') {
      result += `${i18n.t(arrItem[0])}: ${arrItem[1]}\n`;
    }
  }
  return result;
};

export const formatDateDepas = (data: string) => {
  let result = data;
  if (data.length === 8) {
    result = `${data.substring(6, 8)}.${data.substring(4, 6)}.${data.substring(
      0,
      4
    )}`;
  }
  if (result === '0') {
    result = '-';
  }
  return result;
};

export const formatMessageCheckToArrayCheck = (data: string) => {
  let result = data;
  result = result.replaceAll('##,##', '\n');
  return result;
};

export const replaceQuoat = (value?: string, type?: number) => {
  if (value === undefined) return '';
  if (type === 0) {
    return value.replaceAll('_', '"');
  }
  return value.replaceAll('"', '_');
};

export const descrLog = (log: string) => {
  switch (log) {
    case 'deleteUser':
      return i18n.t('logDeleteUser');
    case 'logout':
      return i18n.t('logLogout');
    case 'signIn':
      return i18n.t('logSignIn');
    case 'signUp':
      return i18n.t('logSignUp');
    case 'addGroup':
      return i18n.t('logAddGroup');
    case 'deleteGroup':
      return i18n.t('logDeleteGroup');
    case 'addGroupTask':
      return i18n.t('logAddGroupTask');
    case 'updtateUserFlowmeterGroupId':
      return i18n.t('logUpdtateUserFlowmeterGroupId');
    case 'deleteDevice':
      return i18n.t('logDeleteDevice');
    case 'moveToArch':
      return i18n.t('logMoveToArch');
    case 'updateInfo':
      return i18n.t('logUpdateInfo');
    case 'updateSetup':
      return i18n.t('logUpdateSetup');
    case 'deleteSetup':
      return i18n.t('logDeleteSetup');
    case 'updateFw':
      return i18n.t('logUpdateFw');
    case 'deleteUpdateFw':
      return i18n.t('logDeleteUpdateFw');
    case 'addAppId':
      return i18n.t('logAddAppId');
    case 'deleteAppId':
      return i18n.t('logDeleteAppId');
    case 'deleteTokenId':
      return i18n.t('logDeleteTokenId');
    case 'addToken':
      return i18n.t('logAddToken');
    case 'addTelegramId':
      return i18n.t('logAddTelegramId');
    case 'deleteTelegramId':
      return i18n.t('logDeleteTelegramId');
    case 'updateTelegramIdService':
      return i18n.t('logChangeServiceTelegramId');
    default:
      return log;
  }
};

export const deviceType = (type?: number) => {
  switch (type) {
    case 0:
      return 'G0';
    case 1:
      return 'G1.6';
    case 2:
      return 'G2.5';
    case 3:
      return 'G4';
    case 4:
      return 'G5';
    case 5:
      return 'G6';
    case 6:
      return 'G10';
    case 7:
      return 'G16';
    case 8:
      return 'G25';
    case 9:
      return 'G40';
    case 10:
      return 'G65';
    case 11:
      return 'G100';
    default:
      return type;
  }
};

export const descrActualApp = (dto?: ActualVersDto) => {
  if (dto === undefined) {
    return '';
  }
  switch (dto.neoLocalServer) {
    case 'needUpdate':
      return `${i18n.t('needUpdate')} ${dto.neoLocalServerVer}`;
    default:
      return '';
  }
};

export const getDeviceLicenseDsecr = (short: string) => {
  switch (short) {
    case 'pam':
      return i18n.t('pam');
    case 'ic':
      return i18n.t('iccid');
    case 'pi':
      return i18n.t('procId');
    case 'cp':
      return i18n.t('constSetupResultPres');
    case 'ck':
      return i18n.t('constKomp');
    case 'cdt':
      return i18n.t('constMinTemp');
    case 'cut':
      return i18n.t('constMaxTemp');
    case 'dtl':
      return i18n.t('minTempLimit');
    case 'utl':
      return i18n.t('maxTempLimit');
    case 'cv':
      return i18n.t('contrVal');
    case 'amag':
      return i18n.t('actionMagnet');
    case 'adt':
      return i18n.t('actionMinTemp');
    case 'aut':
      return i18n.t('actionMaxTemp');
    case 'lo':
      return i18n.t('dispOn');
    case 'lr':
      return i18n.t('dispRotate');
    case 'ch':
      return i18n.t('contrHour');
    case 'cd':
      return i18n.t('contrDay');
    case 'ptm':
      return i18n.t('tempMeasPeriod');
    case 's':
      return i18n.t('server');
    case 'shvw':
      return i18n.t('showVolumeRu');
    case 'ml':
      return i18n.t('minSign');
    case 'mt':
      return i18n.t('minGprsTemp');
    case 'dt':
      return i18n.t('deviceType');
    case 'dn':
      return i18n.t('deviceNum');
    case 't':
      return i18n.t('temp');
    case 'p':
      return i18n.t('pres');
    case 'k':
      return i18n.t('komp');
    case 'svs':
      return i18n.t('sumVolSt');
    case 'sves':
      return i18n.t('sumVolErrSt');
    case 'ev':
      return i18n.t('nowErrors');
    case 'ctdt':
      return i18n.t('datetime');
    case 'vb':
      return i18n.t('voltBat');
    case 'vr':
      return i18n.t('voltResBat');
    case 'vp':
      return i18n.t('voltProc');
    case 'tp':
      return i18n.t('tempProc');
    case 'tnb':
      return i18n.t('timeNewBat');
    case 'tb':
      return i18n.t('timeBle');
    case 'tt':
      return i18n.t('timeTotal');
    case 'tl':
      return i18n.t('timeLeft');
    case 'gsc':
      return i18n.t('gprsSendAll');
    case 'tdnb':
      return i18n.t('timeDispNew');
    case 'tgnb':
      return i18n.t('timeGprsNew');
    case 'tbnb':
      return i18n.t('timeBleNew');
    case 'gse':
      return i18n.t('gprsSendErr');
    case 'gso':
      return i18n.t('gprsSendOk');
    case 'td':
      return i18n.t('timeDisp');
    case 'tg':
      return i18n.t('timeGprs');
    case 'tbs':
      return i18n.t('timeBleSleep');
    case 'tbsnb':
      return i18n.t('timeBleNewSleep');
    case 'pn':
      return i18n.t('plombNum');
    case 'fv':
      return i18n.t('fwVers');
    case 'fvd':
      return i18n.t('fwDate');
    case 'depd':
      return i18n.t('batDepDate');
    case 'dept':
      return i18n.t('batDepTime');
    case 'depr':
      return i18n.t('batDepResult');
    case 'im':
      return i18n.t('imei');
    case 'ka':
      return i18n.t('kimpA');
    case 'kb':
      return i18n.t('kimpB');
    case 'kc':
      return i18n.t('kimpC');
    case 'fl':
      return i18n.t('flowMin');
    case 'fh':
      return i18n.t('flowMax');
    case 'mbc':
      return i18n.t('maxBattery');
    case 'tbe':
      return i18n.t('tokBase');
    case 'tdp':
      return i18n.t('toDisp');
    case 'tba':
      return i18n.t('tokBleActiv');
    case 'rf':
      return i18n.t('rtcReal');
    case 'tobs':
      return i18n.t('tokBleSleep');
    case 'cr':
      return i18n.t('calResist');
    case 'msc':
      return i18n.t('gprsMaxSend');
    case 'tio':
      return i18n.t('tempIntOffset');
    case 'sttl':
      return i18n.t('startBat');
    case 'pc':
      return i18n.t('pinCode');
    case 'us':
      return i18n.t('ussdCommand');
    case 'lics':
      return i18n.t('lics');
    case 'smrc':
      return i18n.t('restartCount');
    case 'evm':
      return i18n.t('memmoryErrors');
    case 'b':
      return i18n.t('balance');
    case 'o':
      return i18n.t('operator');
    case 'sl':
      return i18n.t('signLevel');
    case 'sprc':
      return i18n.t('repeatCount');
    case 'svw':
      return i18n.t('sumVol');
    case 'fs':
      return i18n.t('flowSense');
    case 'efm':
      return i18n.t('enableFlowMin');
    case 'vs':
      return i18n.t('volSt');
    case 'ves':
      return i18n.t('volErrSt');
    case 'potreb':
      return i18n.t('potreb');
    case 'lgfs':
      return i18n.t('lgfs');
    case 'sesn':
      return i18n.t('sesn');
    case 'sesm':
      return i18n.t('sesm');
    case 'mqtt':
      return i18n.t('mqtt');
    case 'address':
      return i18n.t('address');
    case 'mg':
      return i18n.t('gasMonitor');
    case 'hv':
      return i18n.t('hv');
    case 'ufc':
      return i18n.t('ufc');
    case 'fp1':
      return i18n.t('fp1');
    case 'fp2':
      return i18n.t('fp2');
    case 'fp3':
      return i18n.t('fp3');
    case 'fp4':
      return i18n.t('fp4');
    case 'fp5':
      return i18n.t('fp5');
    case 'fp6':
      return i18n.t('fp6');
    case 'fp7':
      return i18n.t('fp7');
    case 'fc1':
      return i18n.t('fc1');
    case 'fc2':
      return i18n.t('fc2');
    case 'fc3':
      return i18n.t('fc3');
    case 'fc4':
      return i18n.t('fc4');
    case 'fc5':
      return i18n.t('fc5');
    case 'fc6':
      return i18n.t('fc6');
    case 'fc7':
      return i18n.t('fc7');
    default:
      return short;
  }
};

export const getGroupTaskDto = (groupNum: string, taskArr: TaskDto[]) => {
  const groupTaskDto: GroupTaskDto = {
    groupNum,
    taskConstPres: '',
    taskConstKomp: '',
    taskContrHour: '',
    taskContrDay: '',
    taskContrVal: '',
    taskServerAddress: '',
    taskMinLevel: '',
    taskMinTempGprs: '',
    taskTempMinLimit: '',
    taskConstTempMin: '',
    taskTOptionMin: '',
    taskTempMaxLimit: '',
    taskConstTempMax: '',
    taskTOptionMax: '',
    taskMagnetOption: '',
  };

  for (let i = 0; i < taskArr.length; i += 1) {
    switch (taskArr[i].id) {
      case 'cp':
        groupTaskDto.taskConstPres = taskArr[i].value;
        break;
      case 'ck':
        groupTaskDto.taskConstKomp = taskArr[i].value;
        break;
      case 'ch':
        groupTaskDto.taskContrHour = taskArr[i].value;
        break;
      case 'cd':
        groupTaskDto.taskContrDay = taskArr[i].value;
        break;
      case 'cv':
        groupTaskDto.taskContrVal = taskArr[i].value;
        break;
      case 's':
        groupTaskDto.taskServerAddress = taskArr[i].value;
        break;
      case 'ml':
        groupTaskDto.taskMinLevel = taskArr[i].value;
        break;
      case 'mt':
        groupTaskDto.taskMinTempGprs = taskArr[i].value;
        break;
      case 'dtl':
        groupTaskDto.taskTempMinLimit = taskArr[i].value;
        break;
      case 'cdt':
        groupTaskDto.taskConstTempMin = taskArr[i].value;
        break;
      case 'adt':
        groupTaskDto.taskTOptionMin = taskArr[i].value;
        break;
      case 'utl':
        groupTaskDto.taskTempMaxLimit = taskArr[i].value;
        break;
      case 'cut':
        groupTaskDto.taskConstTempMax = taskArr[i].value;
        break;
      case 'aut':
        groupTaskDto.taskTOptionMax = taskArr[i].value;
        break;
      case 'am':
        groupTaskDto.taskMagnetOption = taskArr[i].value;
        break;
      default:
        break;
    }
  }
  return groupTaskDto;
};

export const getDeviceStageDescription = (stage: number) => {
  switch (stage) {
    case DeviceStageEnum.ALL:
      return i18n.t('flowSystem');
    case DeviceStageEnum.ARCHIVE:
      return i18n.t('flowArchive');
    case DeviceStageEnum.BATTERY:
      return i18n.t('flowBattery');
    case DeviceStageEnum.CONNECT:
      return i18n.t('flowConnect');
    case DeviceStageEnum.SEARCH:
      return i18n.t('flowSearch');
    case DeviceStageEnum.SERTIFICATE:
      return i18n.t('flowSert');
    case DeviceStageEnum.SERVER_LOGS:
      return i18n.t('flowLog');
    case DeviceStageEnum.TASK:
      return i18n.t('activeFlow');
    case DeviceStageEnum.UPDATE:
      return i18n.t('flowUpdate');
    case DeviceStageEnum.WARNING:
      return i18n.t('flowWarning');
    case DeviceStageEnum.VALVE:
      return i18n.t('flowVale');
    default:
      return '';
  }
};

export const getFactoryStageDescription = (stage: number) => {
  switch (stage) {
    case FactoryStageEnum.SERVICE_TOKEN:
      return i18n.t('serviceTokenMenu');
    case FactoryStageEnum.PASSWORDS:
      return i18n.t('passwordsMenu');
    case FactoryStageEnum.BATTERY_ACTIVATE:
      return i18n.t('batteryActivateMenu');
    case FactoryStageEnum.FACTORY_TOKEN:
      return i18n.t('factoryTokenMenu');
    case FactoryStageEnum.LOCAL_SERVER_ACTIVATE:
      return i18n.t('localServer');
    case FactoryStageEnum.LICENSE_RESULT:
      return i18n.t('deviceLicense');
    case FactoryStageEnum.CLOUD_USER:
      return i18n.t('cloudUser');
    case FactoryStageEnum.LOCAL_SERVER_STATE:
      return i18n.t('localServerState');
    default:
      return '';
  }
};

export const getReportTypeDescription = (type: number) => {
  switch (type) {
    case ReportsTypeEnum.HOUR:
      return i18n.t('hourArchive');
    case ReportsTypeEnum.DAY:
      return i18n.t('dayArchive');
    case ReportsTypeEnum.MONTH:
      return i18n.t('monthArchive');
    case ReportsTypeEnum.EVENTS:
      return i18n.t('eventsJournal');
    case ReportsTypeEnum.CHANGES:
      return i18n.t('changesJournal');
    default:
      return '';
  }
};

export const getRootDescription = (type: number) => {
  switch (type) {
    case RootEnum.READ:
      return i18n.t('read');
    case RootEnum.READ_WRITE:
      return i18n.t('readWrite');
    case RootEnum.ADMIN:
      return i18n.t('admin');
    case RootEnum.U1:
      return i18n.t('otherService');
    default:
      return '';
  }
};

export const getRootLevel = () => {
  const root = parseInt(localStorage.getItem(ROOT_NAME) ?? '-1', 10);
  return root;
};

export const getIsCloud = () => {
  let valcloud = false;
  const isCloud = parseInt(localStorage.getItem(IS_CLOUD_NAME) ?? '0', 10);
  if (isCloud) {
    valcloud = true;
  }
  return valcloud;
};

export const getRootArray = () => {
  const rootArray: string[] = [];
  rootArray.push(i18n.t('read'));
  rootArray.push(i18n.t('readWrite'));
  rootArray.push(i18n.t('otherService'));
  return rootArray;
};

export const getActionDescription = (type: number) => {
  switch (type) {
    case ActionMagnetFlowEnum.ONLY_JOURNAL:
      return i18n.t('actionJournalOnly');
    case ActionMagnetFlowEnum.CONTR_VAL:
      return i18n.t('actionContrVal');
    case ActionTempEnum.ONLY_JOURNAL:
      return i18n.t('actionJournalOnly');
    case ActionTempEnum.CONTR_VAL:
      return i18n.t('actionContrVal');
    case ActionTempEnum.CONST_TEMP:
      return i18n.t('actionConstTemp');
    default:
      return '';
  }
};

export const getDispOnDescription = (type: number) => {
  switch (type) {
    case DispEnum.OFF:
      return i18n.t('dispOnStatusOff');
    case DispEnum.ON:
      return i18n.t('dispOnStatusOn');
    default:
      return '';
  }
};

export const getShowVolumeDescription = (type: number) => {
  switch (type) {
    case ShowVolumeEnum.OFF:
      return i18n.t('showVolumeOff');
    case ShowVolumeEnum.ON:
      return i18n.t('showVolumeOn');
    default:
      return '';
  }
};

export const getValveDescription = (type: number) => {
  switch (type) {
    case EnableValveEnum.OFF:
      return i18n.t('valveOff');
    case EnableValveEnum.ON:
      return i18n.t('valveOn');
    default:
      return '';
  }
};

export const getValveTypeDescription = (type: number) => {
  switch (type) {
    case ValveTypeEnum.OFF:
      return i18n.t('valveTypeOff');
    case ValveTypeEnum.ONE:
      return i18n.t('valveTypeOne');
    case ValveTypeEnum.TWO:
      return i18n.t('valveTypeTwo');
    case ValveTypeEnum.SHAR:
      return i18n.t('valveTypeShar');
    default:
      return '';
  }
};

export const getValvePolDescription = (type: number) => {
  switch (type) {
    case ValvePolEnum.PRYAM:
      return i18n.t('valvePolPryam');
    case ValvePolEnum.OBRAT:
      return i18n.t('valvePolObrat');
    default:
      return '';
  }
};

export const getValveTypeArray = () => {
  const arr: string[] = [];
  arr.push(i18n.t('valveTypeOff'));
  arr.push(i18n.t('valveTypeOne'));
  arr.push(i18n.t('valveTypeTwo'));
  arr.push(i18n.t('valveTypeShar'));
  return arr;
};

export const getValvePolArray = () => {
  const arr: string[] = [];
  arr.push(i18n.t('valvePolPryam'));
  arr.push(i18n.t('valvePolObrat'));
  return arr;
};

export const getEnableFlowMinDescription = (type: number) => {
  switch (type) {
    case EnableFlowMinEnum.OFF:
      return i18n.t('enableFlowMinOff');
    case EnableFlowMinEnum.ON:
      return i18n.t('enableFlowMinOn');
    default:
      return '';
  }
};

export const getDispRotateDescription = (type: number) => {
  switch (type) {
    case DispEnum.OFF:
      return i18n.t('dispRotateStatusOff');
    case DispEnum.ON:
      return i18n.t('dispRotateStatusOn');
    default:
      return '';
  }
};

export const getDescrPlombNum = (plombnum?: string) => {
  if (plombnum === undefined) {
    return '';
  }
  if (plombnum === '0') {
    return i18n.t('plombBroken');
  }
  return plombnum;
};

export const getDispRotateArray = () => {
  const dispRotateArray: string[] = [];
  dispRotateArray.push(i18n.t('dispRotateStatusOff'));
  dispRotateArray.push(i18n.t('dispRotateStatusOn'));
  return dispRotateArray;
};

export const getShowVolumeArray = () => {
  const showVolumeArray: string[] = [];
  showVolumeArray.push(i18n.t('showVolumeOff'));
  showVolumeArray.push(i18n.t('showVolumeOn'));
  return showVolumeArray;
};

export const getValveArray = () => {
  const showVolumeArray: string[] = [];
  showVolumeArray.push(i18n.t('valveOn'));
  showVolumeArray.push(i18n.t('valveOff'));
  return showVolumeArray;
};

export const getEnableFlowMin = () => {
  const enableFlowMinArray: string[] = [];
  enableFlowMinArray.push(i18n.t('enableFlowMinOff'));
  enableFlowMinArray.push(i18n.t('enableFlowMinOn'));
  return enableFlowMinArray;
};

export const getDispOnArray = () => {
  const dispOnArray: string[] = [];
  dispOnArray.push(i18n.t('dispOnStatusOff'));
  dispOnArray.push(i18n.t('dispOnStatusOn'));
  return dispOnArray;
};

export const getMagnetFlowActionArray = () => {
  const actionArray: string[] = [];
  actionArray.push(i18n.t('actionJournalOnly'));
  actionArray.push(i18n.t('actionContrVal'));
  return actionArray;
};

export const getTempActionArray = () => {
  const actionArray: string[] = [];
  actionArray.push(i18n.t('actionJournalOnly'));
  actionArray.push(i18n.t('actionContrVal'));
  actionArray.push(i18n.t('actionConstTemp'));
  return actionArray;
};

export const isMobileView = () => {
  const result = window.innerWidth < 820;
  return result;
};

export const otherToDataGrid = {
  autoHeight: true,
  showCellRightBorder: true,
  showColumnRightBorder: true,
};

export const getViewType = () => {
  const result = window.innerWidth;
  if (result < 820) {
    return MOBILE_VIEW;
  }
  if (result < 1440) {
    return PAD_VIEW;
  }
  return PC_VIEW;
};

export const getReportTypeArray = () => {
  const reportType: string[] = [];
  if (getViewType() === MOBILE_VIEW) {
    reportType.push(i18n.t('hourArchiveShort'));
    reportType.push(i18n.t('dayArchiveShort'));
    reportType.push(i18n.t('monthArchiveShort'));
    reportType.push(i18n.t('eventsJournalShort'));
    reportType.push(i18n.t('changesJournalShort'));
  } else {
    reportType.push(i18n.t('hourArchive'));
    reportType.push(i18n.t('dayArchive'));
    reportType.push(i18n.t('monthArchive'));
    reportType.push(i18n.t('eventsJournal'));
    reportType.push(i18n.t('changesJournal'));
  }

  return reportType;
};

export const getSelectedLng = () => {
  let lng = localStorage.getItem('language');
  if (lng === null) {
    lng = LANGUAGE_RUS;
  }
  return lng;
};

export const getMainGridSize = () => {
  const result = getViewType();
  if (result === MOBILE_VIEW) {
    return 12;
  }
  if (result === PAD_VIEW) {
    return 6;
  }
  return 4;
};

export const getDefaultAlertProps = () => {
  const alertApp: IAlertProps = {
    message: '',
    severity: AlertSeverityEnum.error,
  };
  return alertApp;
};

export const getDefaultApiResult = () => {
  const apiResult: IApiResult = {
    isSuccess: false,
    status: -1,
    data: null,
    alert: getDefaultAlertProps(),
  };
  return apiResult;
};

export const changeFormatDateWithDiff = (
  inputValue?: string,
  type?: string
) => {
  let result = '';
  if (inputValue) {
    const arrayDate = inputValue.split('.');
    if (arrayDate.length === 3) {
      if (type === undefined) {
        result = `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}`;
      } else {
        if (type === 'date') {
          result = `${arrayDate[2]}-${arrayDate[1]}-${arrayDate[0]}`;
        }
        if (type === 'month') {
          result = `${arrayDate[2]}-${arrayDate[1]}`;
        }
        if (type === 'number') {
          result = `${arrayDate[2]}`;
        }
      }
    } else {
      result = inputValue;
    }
  }
  return result;
};

export const changeFormatDateWithPoint = (inputValue?: string) => {
  let result = '';
  if (inputValue) {
    const arrayDate = inputValue.split('-');
    if (arrayDate.length === 3) {
      result = `${arrayDate[0]}.${arrayDate[1]}.${arrayDate[2]}`;
    } else {
      result = inputValue;
    }
  }
  return result;
};

export const setToken = (tokenType: string) =>
  `${localStorage.getItem(tokenType)}`;

export const getNameArray = (array: any, hasAll?: boolean, sortId?: number) => {
  const arrName = [];
  if (hasAll) {
    arrName.push('Все');
  }
  for (let i = 0; i < array.length; i += 1) {
    if (sortId !== undefined) {
      if (sortId === array[i].divisionId) {
        arrName.push(array[i].name.replaceAll('_', '"'));
      }
    } else {
      arrName.push(array[i].name.replaceAll('_', '"'));
    }
  }
  return arrName;
};

export const getNameTaskArray = (array: any) => {
  const arrName = [];

  for (let i = 0; i < array.length; i += 1) {
    arrName.push(array[i].name);
  }
  return arrName;
};

export const getTypeTaskArray = (array: any) => {
  const arrName = [];

  for (let i = 0; i < array.length; i += 1) {
    arrName.push(array[i].type);
  }
  return arrName;
};

export const getSpinnerTaskArray = (array: any) => {
  const arrName = [];

  for (let i = 0; i < array.length; i += 1) {
    arrName.push(array[i].spinner);
  }
  return arrName;
};

export const getTooltipErrors = (
  errors?: string,
  timeMagnet?: string,
  timeFlow?: string,
  timeTemp?: string
) => {
  if (errors === undefined) {
    return undefined;
  }
  if (errors === '0') {
    return i18n.t('noError');
  }
  const arrErr = errors.split(',');
  let result = '';
  for (let i = 0; i < arrErr.length; i += 1) {
    arrErr[i] = arrErr[i].replace(' ', '');
    if (arrErr[i] === '1') {
      result = `${result}${i18n.t('errFlowMax')},\n`;
    }
    if (arrErr[i] === '2') {
      result = `${result}${i18n.t('errTempLimit')},\n`;
    }
    if (arrErr[i] === '3') {
      result = `${result}${i18n.t('errMagnet')},\n`;
    }
    if (arrErr[i] === '4') {
      result = `${result}${i18n.t('errCalibErr')},\n`;
    }
    if (arrErr[i] === '5') {
      result = `${result}${i18n.t('errBox')},\n`;
    }
    if (arrErr[i] === '6') {
      result = `${result}${i18n.t('errAdc')},\n`;
    }
    if (arrErr[i] === '7') {
      result = `${result}${i18n.t('errBox2')},\n`;
    }
    if (arrErr[i] === '8') {
      result = `${result}${i18n.t('errMinFlow')},\n`;
    }
    if (arrErr[i] === '9') {
      result = `${result}${i18n.t('errEepromFlash')},\n`;
    }
    if (arrErr[i] === '10') {
      result = `${result}${i18n.t('err10')},\n`;
    }
    if (arrErr[i] === '11') {
      result = `${result}${i18n.t('err11')},\n`;
    }
    if (arrErr[i] === '12') {
      result = `${result}${i18n.t('err12')},\n`;
    }
    if (arrErr[i] === '13') {
      result = `${result}${i18n.t('err13')},\n`;
    }
    if (arrErr[i] === '14') {
      result = `${result}${i18n.t('err14')},\n`;
    }
    if (arrErr[i] === '15') {
      result = `${result}${i18n.t('err15')},\n`;
    }
    if (arrErr[i] === '16') {
      result = `${result}${i18n.t('err16')},\n`;
    }
    if (arrErr[i] === '17') {
      result = `${result}${i18n.t('err17')},\n`;
    }
    if (arrErr[i] === '18') {
      result = `${result}${i18n.t('err18')},\n`;
    }
    if (arrErr[i] === '19') {
      result = `${result}${i18n.t('err19')},\n`;
    }
    if (arrErr[i] === '20') {
      result = `${result}${i18n.t('err20')},\n`;
    }
    if (arrErr[i] === '21') {
      result = `${result}${i18n.t('err21')},\n`;
    }
    if (arrErr[i] === '22') {
      result = `${result}${i18n.t('err22')},\n`;
    }
    if (arrErr[i] === '23') {
      result = `${result}${i18n.t('err23')},\n`;
    }
    if (arrErr[i] === '24') {
      result = `${result}${i18n.t('err24')},\n`;
    }
    if (arrErr[i] === '25') {
      result = `${result}${i18n.t('err25')},\n`;
    }
    if (arrErr[i] === '26') {
      result = `${result}${i18n.t('err26')},\n`;
    }
    if (arrErr[i] === '27') {
      result = `${result}${i18n.t('err27')},\n`;
    }
  }
  if (arrErr.length > 0) {
    result = result.substring(0, result.length - 2);
    if (
      (timeMagnet !== undefined && timeMagnet !== '0' && timeMagnet !== '') ||
      (timeFlow !== undefined && timeFlow !== '0' && timeFlow !== '') ||
      (timeTemp !== undefined && timeTemp !== '0' && timeTemp !== '')
    ) {
      result = `${result}\n\n`;
    }
  }

  if (timeMagnet !== undefined) {
    if (timeMagnet !== '0' && timeMagnet !== '') {
      result = `${result}${i18n.t('magnetTime')} ${timeMagnet} с,\n`;
    }
  }
  if (timeFlow !== undefined) {
    if (timeFlow !== '0' && timeFlow !== '') {
      result = `${result}${i18n.t('flowTime')} ${timeFlow} с,\n`;
    }
  }
  if (timeTemp !== undefined) {
    if (timeTemp !== '0' && timeTemp !== '') {
      result = `${result}${i18n.t('tempTime')} ${timeTemp} с,\n`;
    }
  }

  if (
    (timeMagnet !== undefined && timeMagnet !== '0' && timeMagnet !== '') ||
    (timeFlow !== undefined && timeFlow !== '0' && timeFlow !== '') ||
    (timeTemp !== undefined && timeTemp !== '0' && timeTemp !== '')
  ) {
    result = result.substring(0, result.length - 2);
  }

  return result;
};

export const getSumNomAndMemErro = (errorsNow?: string, errorsMem?: string) => {
  if (errorsNow === undefined || errorsMem === undefined) {
    return undefined;
  }
  if (errorsNow === '0' && errorsMem === '0') {
    return i18n.t('noError');
  }
  const arrErrNow = errorsNow.replaceAll(' ', '').split(',');
  const arrErrMem = errorsMem.replaceAll(' ', '').split(',');

  let allErr: AllErrorsDto[] = [];
  for (let i = 0; i < arrErrNow.length; i += 1) {
    if (parseInt(arrErrNow[i], 10) !== 0) {
      allErr.push({
        id: parseInt(arrErrNow[i], 10),
        error: arrErrNow[i],
        error2: `${arrErrNow[i]}`,
      });
    }
  }
  for (let i = 0; i < arrErrMem.length; i += 1) {
    let isAdd = 1;
    for (let j = 0; j < arrErrNow.length; j += 1) {
      if (arrErrMem[i] === arrErrNow[j]) {
        isAdd = 0;
      }
    }
    if (isAdd === 1 && arrErrMem[i] !== '0') {
      allErr.push({
        id: parseInt(arrErrMem[i], 10),
        error: arrErrMem[i],
        error2: `${arrErrMem[i]}*`,
      });
    }
  }
  allErr = allErr.sort((a, b) => (a.id > b.id ? 1 : -1));
  let result = '';
  for (let i = 0; i < allErr.length; i += 1) {
    const text = allErr[i].error2;
    result = `${result}${text}, `;
  }
  if (allErr.length > 0) {
    result = result.substring(0, result.length - 2);
  }

  return result;
};

export const getTooltipErrorsNowAndMem = (
  errorsNow?: string,
  errorsMem?: string
) => {
  if (errorsNow === undefined || errorsMem === undefined) {
    return undefined;
  }
  if (errorsNow === '0' && errorsMem === '0') {
    return i18n.t('noError');
  }
  const arrErrNow = errorsNow.replaceAll(' ', '').split(',');
  const arrErrMem = errorsMem.replaceAll(' ', '').split(',');

  let allErr: AllErrorsDto[] = [];
  for (let i = 0; i < arrErrNow.length; i += 1) {
    allErr.push({
      id: parseInt(arrErrNow[i], 10),
      error: arrErrNow[i],
      error2: `${arrErrNow[i]}`,
    });
  }
  for (let i = 0; i < arrErrMem.length; i += 1) {
    let isAdd = 1;
    for (let j = 0; j < arrErrNow.length; j += 1) {
      if (arrErrMem[i] === arrErrNow[j]) {
        isAdd = 0;
      }
    }
    if (isAdd === 1) {
      allErr.push({
        id: parseInt(arrErrMem[i], 10),
        error: arrErrMem[i],
        error2: `${arrErrMem[i]}*`,
      });
    }
  }
  allErr = allErr.sort((a, b) => (a.id > b.id ? 1 : -1));
  let result = '';
  for (let i = 0; i < allErr.length; i += 1) {
    if (allErr[i].error2 === '1') {
      result = `${result}${i18n.t('errFlowMax')},\n`;
    }
    if (allErr[i].error2 === '2') {
      result = `${result}${i18n.t('errTempLimit')},\n`;
    }
    if (allErr[i].error2 === '3') {
      result = `${result}${i18n.t('errMagnet')},\n`;
    }
    if (allErr[i].error2 === '4') {
      result = `${result}${i18n.t('errCalibErr')},\n`;
    }
    if (allErr[i].error2 === '5') {
      result = `${result}${i18n.t('errBox')},\n`;
    }
    if (allErr[i].error2 === '6') {
      result = `${result}${i18n.t('errAdc')},\n`;
    }
    if (allErr[i].error2 === '7') {
      result = `${result}${i18n.t('errBox2')},\n`;
    }
    if (allErr[i].error2 === '8') {
      result = `${result}${i18n.t('errMinFlow')},\n`;
    }
    if (allErr[i].error2 === '9') {
      result = `${result}${i18n.t('errEepromFlash')},\n`;
    }
    if (allErr[i].error2 === '27') {
      result = `${result}${i18n.t('err27')},\n`;
    }

    if (allErr[i].error2 === '1*') {
      result = `${result}${i18n.t('errFlowMaxMem')},\n`;
    }
    if (allErr[i].error2 === '2*') {
      result = `${result}${i18n.t('errTempLimitMem')},\n`;
    }
    if (allErr[i].error2 === '3*') {
      result = `${result}${i18n.t('errMagnetMem')},\n`;
    }
    if (allErr[i].error2 === '4*') {
      result = `${result}${i18n.t('errCalibErrMem')},\n`;
    }
    if (allErr[i].error2 === '5*') {
      result = `${result}${i18n.t('errBoxMem')},\n`;
    }
    if (allErr[i].error2 === '6*') {
      result = `${result}${i18n.t('errAdcMem')},\n`;
    }
    if (allErr[i].error2 === '7*') {
      result = `${result}${i18n.t('errBox2Mem')},\n`;
    }
    if (allErr[i].error2 === '8*') {
      result = `${result}${i18n.t('errMinFlowMem')},\n`;
    }
    if (allErr[i].error2 === '9*') {
      result = `${result}${i18n.t('errEepromFlashMem')},\n`;
    }
    if (allErr[i].error2 === '27*') {
      result = `${result}${i18n.t('err27Mem')},\n`;
    }
  }
  if (allErr.length > 0) {
    result = result.substring(0, result.length - 2);
  }

  return result;
};

export const getIdTaskArray = (array: any) => {
  const arrId = [];
  for (let i = 0; i < array.length; i += 1) {
    arrId.push(array[i].id);
  }
  return arrId;
};

export const formTaskArray = (arrayChoose?: TaskDto[]) => {
  const tasks: TaskDto[] = [];
  tasks.push({
    id: 'cp',
    name: i18n.t('constPres'),
    type: 'number',
  });
  tasks.push({
    id: 'ck',
    name: i18n.t('constKomp'),
    type: 'number',
  });
  tasks.push({
    id: 'ch',
    name: i18n.t('contrHour'),
    type: 'number',
  });
  tasks.push({
    id: 'cd',
    name: i18n.t('contrDay'),
    type: 'number',
  });
  tasks.push({
    id: 'cv',
    name: i18n.t('contrVal'),
    type: 'number',
  });
  tasks.push({
    id: 's',
    name: i18n.t('server'),
    type: 'text',
  });
  tasks.push({ id: 'ml', name: i18n.t('minSign'), type: 'number' });
  tasks.push({ id: 'mt', name: i18n.t('minGprsTemp'), type: 'number' });
  tasks.push({
    id: 'dtl',
    name: i18n.t('minTempLimit'),
    type: 'number',
  });
  tasks.push({
    id: 'cdt',
    name: i18n.t('constMinTemp'),
    type: 'number',
  });
  tasks.push({
    id: 'adt',
    name: i18n.t('actionMinTemp'),
    type: 'number',
    spinner: ACTION_TEMP,
  });
  tasks.push({
    id: 'utl',
    name: i18n.t('maxTempLimit'),
    type: 'number',
  });
  tasks.push({
    id: 'cut',
    name: i18n.t('constMaxTemp'),
    type: 'number',
  });
  tasks.push({
    id: 'aut',
    name: i18n.t('actionMaxTemp'),
    type: 'number',
    spinner: ACTION_TEMP,
  });
  tasks.push({
    id: 'am',
    name: i18n.t('actionMagnet'),
    type: 'number',
    spinner: ACTION_MAGNET_FLOW,
  });
  if (arrayChoose) {
    for (let i = 0; i < arrayChoose.length; i += 1) {
      tasks.filter((a) => a.id !== arrayChoose[i].id);
    }
  }
  return tasks;
};

export const getKategoryDescription = (type: number) => {
  switch (type) {
    case KategoryEnum.NOT_SELECTED:
      return i18n.t('notChoose');
    case KategoryEnum.PEOPLE:
      return i18n.t('people');
    case KategoryEnum.INDUSTRY:
      return i18n.t('factory');
    default:
      return '';
  }
};

export const getKategoryArray = () => {
  const kategDto: string[] = [];
  kategDto.push(i18n.t('notChoose'));
  kategDto.push(i18n.t('people'));
  kategDto.push(i18n.t('factory'));
  return kategDto;
};

export const getIdArray = (array: any, hasAll?: boolean) => {
  const arrId = [];
  if (hasAll) {
    arrId.push(-1);
  }
  for (let i = 0; i < array.length; i += 1) {
    arrId.push(array[i].id);
  }
  return arrId;
};

export const getNumberFromLocal = (name: string) => {
  let result = localStorage.getItem(name) || '-1';
  if (Number.isNaN(parseInt(result, 10))) {
    result = '-1';
  }
  return parseInt(result, 10);
};

export const getBoardVersion = (fw: string) => {
  const ver = parseInt(fw, 10);
  // eslint-disable-next-line no-bitwise
  const byte1 = (ver & 0xff000000) >> 24;
  // eslint-disable-next-line no-bitwise
  const byte2 = (ver & 0x00ff0000) >> 16;
  return `${byte1}.${byte2}`;
};

export const getFwVersion = (fw: string) => {
  const ver = parseInt(fw, 10);
  // eslint-disable-next-line no-bitwise
  const byte1 = (ver & 0x0000ff00) >> 8;
  // eslint-disable-next-line no-bitwise
  const byte2 = ver & 0x000000ff;
  return `${byte1}.${byte2}`;
};

export const getDefaultDateToReport = (day: number, type?: string) => {
  const nowDate = new Date().getTime();
  const nowFormatDate = new Date(nowDate - day * 24 * 60 * 60 * 1000);
  const result = nowFormatDate.toLocaleDateString();
  return changeFormatDateWithDiff(result, type);
};
